import { Component } from 'vue-property-decorator'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import Container from 'typedi'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'

interface AuditLogEntry {
  request: string;
  date_changed: string;
  entity: string;
  updated_by_user: string | number;
  updated_by_admin: object;
  updated_by_string: string;
  values: object[];
}

@Component({
  name: 'GtrAuditLogTab'
})
export default class GtrAuditLogTab extends GtrSuper {
  data () {
    return {
      activity_logs: {
        loading: false,
        headers: [
          {
            text: 'Description',
            align: 'left',
            sortable: false,
            value: 'description'
          },
          {
            text: 'Date Changed',
            align: 'left',
            sortable: false,
            value: 'date_changed'
          },
          {
            text: 'Updated By',
            align: 'left',
            sortable: false,
            value: 'updated_by'
          },
          {
            text: 'Changes',
            align: 'left',
            sortable: false,
            value: 'old_value'
          }
        ]
      }
    }
  }

  get auditLogTable () {
    const processedAuditLogs: AuditLogEntry[] = []
    this.$data.activity_logs.loading = true

    try {
      const auditLogs = this.$store.state.registration.registrationAuditLog
      const registrationAuditLogGrouped = {}

      for (const itemIndex in auditLogs.data) {
        const item = auditLogs.data[itemIndex]
        const requestId = item.request_id + '_' + item.entity
        if (!registrationAuditLogGrouped[requestId]) {
          registrationAuditLogGrouped[requestId] = []
        }
        registrationAuditLogGrouped[requestId].push(item)
      }
      for (const key in registrationAuditLogGrouped) {
        const item: AuditLogEntry = {
          request: '',
          date_changed: '',
          entity: '',
          updated_by_user: 0,
          updated_by_admin: {},
          updated_by_string: '',
          values: []
        }
        item.request = registrationAuditLogGrouped[key][0].request_id
        item.date_changed = registrationAuditLogGrouped[key][0].date_changed
        item.entity = registrationAuditLogGrouped[key][0].entity
        item.updated_by_user = registrationAuditLogGrouped[key][0].updated_by_user
        item.updated_by_admin = registrationAuditLogGrouped[key][0].updated_by_admin
        item.updated_by_string = registrationAuditLogGrouped[key][0].updated_by_string

        for (const val in registrationAuditLogGrouped[key]) {
          item.values.push(
            {
              field_info: registrationAuditLogGrouped[key][val].field_info,
              field: registrationAuditLogGrouped[key][val].field,
              old_value: registrationAuditLogGrouped[key][val].old_value,
              new_value: registrationAuditLogGrouped[key][val].new_value,
              entity: registrationAuditLogGrouped[key][val].entity,
              option_group: registrationAuditLogGrouped[key][val].option_group,
              option: registrationAuditLogGrouped[key][val].option,
              qty: registrationAuditLogGrouped[key][val].total_option_qty
            }
          )
        }
        processedAuditLogs.push(item)
      }
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
    }

    this.$data.activity_logs.loading = false
    return processedAuditLogs
  }
}
