import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import { Component, Ref, Prop, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import Container from 'typedi'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'

@Component({
  name: 'GtrEditLeadsAppCodeForm',
  computed: {
    ...mapState('payment', ['payment_processor', 'payment_properties', 'payment_processor_widget', 'payment_settings']),
    ...mapState('registration', ['registrationFees', 'registrationTransactions']),
    ...mapState('event', ['event'])
  }
})
export default class GtrEditLeadsAppCodeForm extends GtrSuper {
  @Prop({ required: true, type: Boolean, default: false })
    visible: boolean | undefined

  @Prop({ required: true, type: Object, default: {} })
  appCode: object|undefined

  @Ref()
  readonly observerForm!: InstanceType<typeof ValidationObserver>;

  data () {
    return {
      _registration: null,
      submitting: false,
      showForm: false,
      formWidth: 800,
      elements: '',
      current_capture_transaction: false,
      application: 'Leads',
      cap: 3,
      model: {}
    }
  }

  @Watch('appCode', { immediate: true })
  onAppCodeChange (payload: any) {
    if (payload) {
      this.$data.model = payload
    }
  }

  @Watch('visible', { immediate: true })
  onVisibleValueChange (newVisibleValue: boolean) {
    this.$data.showForm = newVisibleValue
  }

  async mounted () {
    this.cleanFormModel()
    if (this.$data._registration === null) {
      await this.fetchRegistration()
    }
    const event = this.$store.state.event.event
    if (event) {
      this.$data._event = event
    } else {
      await this.fetchEvent()
    }
  }

  private async fetchEvent () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('event/fetchEvent', this.$route.params.event_uuid)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async fetchRegistration () {
    try {
      this.$data.loading = true
      const payload = {
        event_uuid: this.$route.params.event_uuid,
        participant_uuid: this.$route.params.attendee_uuid
      }
      const response = await this.$store.dispatch('registration/getRegistration', payload)
      this.$data._registration = response.data
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  get event_identifier () {
    return this.$data._event.event_identifier
  }

  async submit () {
    const form = (this.$refs.editCodeForm as HTMLFormElement)
    if (form.validate()) {
      try {
        this.$data.submitting = true
        const payload: any = {
          event_uuid: this.$route.params.event_uuid,
          participant_uuid: this.$route.params.attendee_uuid,
          app_code_uuid: this.$data.model.uuid,
          data: {
            cap: this.$data.model.cap
          }
        }
        await this.$store.dispatch('registration/updateAppCode', payload)
        Container.get(Notification).success('App Code successfully edited.')
        this.$bus.$emit('gtr.level-one.edit-blacklist')
        this.$data.model.name = null
        this.observerForm.reset()
        this.onClose()
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.submitting = false
      }
    }
  }

  cleanFormModel () {
    this.$data.application = ''
    this.$data.cap = ''
    this.observerForm.reset()
  }

  onClose () {
    this.cleanFormModel()
    this.$emit('close')
  }
}
