import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import { Component, Ref, Prop, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import Container from 'typedi'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'

@Component({
  name: 'GtrAddLeadsAppCodeForm',
  computed: {
    ...mapState('payment', ['payment_processor', 'payment_properties', 'payment_processor_widget', 'payment_settings']),
    ...mapState('registration', ['registrationFees', 'registrationTransactions']),
    ...mapState('event', ['event'])
  }
})
export default class GtrAddLeadsAppCodeForm extends GtrSuper {
  @Prop({ required: true, type: Boolean, default: false })
    visible: boolean | undefined

  @Ref()
  readonly observerAddTransactionForm!: InstanceType<typeof ValidationObserver>;

  data () {
    return {
      _registration: null,
      submitting: false,
      showForm: false,
      formWidth: 800,
      elements: '',
      current_capture_transaction: false,
      apps: [
        {
          label: 'Leads',
          value: 'Leads'
        }
      ],
      application: 'Leads',
      cap: 3
    }
  }

  @Watch('visible', { immediate: true })
  onVisibleValueChange (newVisibleValue: boolean) {
    this.$data.showForm = newVisibleValue
  }

  async mounted () {
    this.cleanFormModel()
    if (this.$data._registration === null) {
      await this.fetchRegistration()
    }
    const event = this.$store.state.event.event
    if (event) {
      this.$data._event = event
    } else {
      await this.fetchEvent()
    }
    this.$data.cap = this.defaultActivationQty
  }

  get defaultActivationQty () {
    if (this.$store !== undefined && this.$store.state.event !== undefined && this.$store.state.event.eventAllContent !== undefined) {
      return this.$store.state.event.eventAllContent.lr_settings.default_activation_qty
    }
    return 3
  }

  private async fetchEvent () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('event/fetchEvent', this.$route.params.event_uuid)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async fetchRegistration () {
    try {
      this.$data.loading = true
      const payload = {
        event_uuid: this.$route.params.event_uuid,
        participant_uuid: this.$route.params.attendee_uuid
      }
      const response = await this.$store.dispatch('registration/getRegistration', payload)
      this.$data._registration = response.data
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  get event_identifier () {
    return this.$data._event.event_identifier
  }

  async addAppCode () {
    try {
      this.$data.loading = true
      this.$data.submitting = true
      const payload = {
        event_uuid: this.$data._event.uuid,
        participant_uuid: this.$data._registration.uuid,
        data: {
          application: this.$data.application,
          cap: this.$data.cap
        }
      }
      await this.$store.dispatch('registration/createAppCode', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
      this.$data.submitting = false
      this.cleanFormModel()
      this.onClose()
    }
  }

  cleanFormModel () {
    this.$data.application = ''
    this.$data.cap = this.defaultActivationQty
    this.observerAddTransactionForm.reset()
  }

  onClose () {
    this.cleanFormModel()
    this.$emit('close')
  }
}
