import { Component, Ref, Prop } from 'vue-property-decorator'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import { mapState } from 'vuex'
import { ValidationObserver } from 'vee-validate'

@Component({
  name: 'GtrRefundForm',
  computed: {
    ...mapState('registration', ['registrationFees', 'participantsGroup'])
  }
})
export default class GtrRefundForm extends GtrSuper {
  @Ref()
  readonly observerAddTransactionForm!: InstanceType<typeof ValidationObserver>;

  @Prop({ required: true, type: Boolean, default: false }) visible: boolean | undefined;

  @Prop({ required: true, type: Object }) transaction: Record<string, any> | undefined;

  itemsToRefund: Array<Record<string, any>> = [];

  refundLineItemsHeaders = [
    {
      text: 'Line Item',
      value: 'name'
    }, {
      text: 'Amount',
      value: 'qty'
    }, {
      text: 'Total',
      value: 'total'
    }, {
      text: 'Refund Transaction',
      value: 'refundTransaction'
    }

  ]

  registrationFees!: any;
  participantsGroup!: any;

  get showForm () {
    return this.visible
  }

  get groupMemberNamesHash () {
    if (!this?.participantsGroup?.participant_group_participants) {
      return {}
    }
    return this.participantsGroup.participant_group_participants.reduce((acc, p) => {
      const { uuid, full_name } = p.participant.participant_data
      if (uuid && full_name) {
        acc[uuid] = full_name
      }
      return acc
    }, {})
  }

  get refundAmount () {
    let total = 0
    for (const item of this.itemsToRefund) {
      total += item.total
    }
    return total
  }

  get paidLineItems () {
    const { line_items = [] } = this.transaction || {}
    return line_items.map((item) => {
      let name = item.name
      if (Object.keys(this.groupMemberNamesHash).includes(item.participant_uuid)) {
        name = `${this.groupMemberNamesHash[item.participant_uuid]} - ${item.name}`
      }
      return {
        ...item,
        name
      }
    })
  }

  get currencySymbol () {
    return this.registrationFees.currency
  }

  handleRefund () {
    const refundPayload: {
      transaction_id: string;
      line_items: Array<Record<string, any>>;
    } = {
      // eslint-disable-next-line
      transaction_id: this.transaction!.uuid,
      line_items: []
    }

    if (this.itemsToRefund.length > 0) {
      refundPayload.line_items = this.itemsToRefund
      this.$emit('action', refundPayload)
    }
    this.handleClose()
  }

  handleClose () {
    this.itemsToRefund = []
    this.$emit('close')
  }
}
