import { Component, Prop } from 'vue-property-decorator'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import { mapState } from 'vuex'
import GtrAddQualifierForm from '../../forms/add-qualifier.form.vue'
import GtrAddLeadsAppCodeForm from '../../forms/add-leads-app-code.form.vue'
import GtrEditLeadsAppCodeForm from '../../forms/edit-leads-app-code.form.vue'
import Container from 'typedi'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'

interface LeadsItem {
  participant: string;
  scan_time: string;
  barcode: string;
  notes: string;
  used_code: string;
  media: any;
  qualifiers: any;
}

@Component({
  name: 'GtrLeadsTab',
  computed: {
    ...mapState('formbuilder', ['form']),
    ...mapState('attendee', ['attendees']),
    ...mapState('security', ['currentUser'])
  },
  components: {
    'gtr-new-qualifier-form': GtrAddQualifierForm,
    'gtr-edit-leads-app-code-form': GtrEditLeadsAppCodeForm,
    'gtr-new-leads-app-code-form': GtrAddLeadsAppCodeForm
  }
})
export default class GtrLeadsTab extends GtrSuper {
  event_uuid = '';

  attendee_uuid = '';

  data () {
    return {
      showUnresolvedLeadsModal: false,
      addLeadData: {
        modal: false,
        items: [],
        lead: null
      },
      tables: {
        app_codes: {
          loading: false,
          headers: [{
            text: 'Code',
            align: 'start',
            sortable: false,
            value: 'code'
          },
          {
            text: 'Used',
            align: 'start',
            sortable: false,
            value: 'used_codes_count'
          },
          {
            text: 'Cap',
            align: 'start',
            sortable: false,
            value: 'cap'
          },
          {
            text: '',
            value: 'actions',
            searchable: false,
            sortable: false,
            width: '90px'
          }]
        },
        leads: {
          loading: false,
          headers: [{
            text: 'Scan Time',
            align: 'start',
            sortable: false,
            value: 'scan_time'
          },
          {
            text: 'Barcode',
            align: 'start',
            sortable: false,
            value: 'barcode'
          },
          {
            text: 'Notes',
            align: 'start',
            sortable: false,
            value: 'notes'
          },
          {
            text: 'Used Code',
            align: 'start',
            sortable: false,
            value: 'used_code'
          },
          {
            text: 'Media',
            align: 'start',
            sortable: false,
            value: 'media'
          },
          {
            text: 'Qualifiers',
            align: 'start',
            sortable: false,
            value: 'qualifiers'
          },
          {
            text: 'Participant',
            align: 'start',
            sortable: false,
            value: 'participant'
          }]
        },
        unresolvedLeads: {
          loading: false,
          headers: [{
            text: 'Scan Time',
            align: 'start',
            sortable: false,
            value: 'scan_time'
          },
          {
            text: 'Barcode',
            align: 'start',
            sortable: false,
            value: 'barcode'
          },
          {
            text: 'Notes',
            align: 'start',
            sortable: false,
            value: 'notes'
          },
          {
            text: 'Used Code',
            align: 'start',
            sortable: false,
            value: 'used_code'
          },
          {
            text: 'Media',
            align: 'start',
            sortable: false,
            value: 'media'
          },
          {
            text: 'Qualifiers',
            align: 'start',
            sortable: false,
            value: 'qualifiers'
          },
          {
            text: 'Participant',
            align: 'start',
            sortable: false,
            value: 'participant'
          },
          {
            text: '',
            align: 'start',
            sortable: false,
            value: 'actions'
          }]
        },
        qualifiers: {
          loading: false,
          headers: [{
            text: 'Description',
            align: 'start',
            sortable: false,
            value: 'description'
          },
          {
            text: 'Type',
            align: 'start',
            sortable: false,
            value: 'type'
          },
          {
            text: 'Barcode',
            align: 'start',
            sortable: false,
            value: 'barcode'
          }]
        }
      },
      forms: {
        showAddQualifiersForm: false,
        showAddLeadsAppCodeForm: false
      },
      unresolvedLeadsUuidToUse: null,
      unknownUuid: null,
      codeDialog: false,
      showedEditCode: {},
      registrationLeadsAppCodesResult: null,
      attendeeLeadsResolved: [],
      attendeeLeadsUnresolved: []
    }
  }

  async created () {
    this.event_uuid = this.$route.params.event_uuid
    this.attendee_uuid = this.$route.params.attendee_uuid
    await this.$store.dispatch('security/getCurrentUser')
  }

  async mounted () {
    try {
      await this.$store.dispatch('formbuilder/getForm', { event_uuid: this.event_uuid })
      await this.fetchAttendeeLeadsAppCodes()
      await this.fetchAttendeeQualifiers()
      await this.fetchAttendeeLeads()
      await this.$store.dispatch('attendee/fetchAttendees', this.event_uuid)
      const scan_time = new Date().toISOString()
      const uuidsToSkip = [...this.$store.state.registration.registrationLeads.map(l => l?.participant_short?.uuid), this.attendee_uuid]
      const attendees = ((this as any).attendees?.data || []).filter(a => !uuidsToSkip.includes(a?.participant_data?.uuid))
      const items = attendees.map(a => {
        const { participant_data: data } = a
        const [barcode] = data.barcodes
        return {
          text: `${data.first_name} ${data.last_name}`,
          value: { barcode, scan_time }
        }
      })
      // const existingLeadsBarcodes = this.$data.attendeeLeadsResolved.map(l => l.barcode)
      this.$data.addLeadData.items = items
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  handleAddLeadClick () {
    this.handleAddLeadModalOpen()
  }

  handleAddLeadModalOpen () {
    this.$data.addLeadData.modal = true
  }

  handleAddLeadModalClose () {
    Object.assign(this.$data.addLeadData, {
      modal: false,
      lead: null
    })
  }

  get isCurrentUserSuperAdmin (): boolean {
    if ((this as any).currentUser) {
      return (this as any).currentUser.access_level === 'SUPER_ADMIN'
    }
    return false
  }

  async addLead () {
    try {
      const { event_uuid, attendee_uuid: participant_uuid } = this.$route.params
      await this.$store.dispatch('leads/addLead', { event_uuid, participant_uuid, data: this.$data.addLeadData.lead })
      await this.fetchAttendeeLeads()
      Container.get(Notification).success('Lead successfully added')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.handleAddLeadModalClose()
    }
  }

  handleShowUnresolvedLeadsModalClose () {
    this.$data.showUnresolvedLeadsModal = false
    this.$data.unresolvedLeadsUuidToUse = null
    this.$data.unknownUuid = null
  }

  handleHowUnresolvedLeadsModalOpen (unknownUuid) {
    this.$data.unknownUuid = unknownUuid
    this.$data.showUnresolvedLeadsModal = true
  }

  get attendeesCondensed () {
    return ((this as any)?.attendees?.data || []).map(item => item.condensed_data)
  }

  get attendeeItemsForUnresolvedLeadsSelect () {
    return this.attendeesCondensed.map(item => {
      return {
        text: `${item.first_name} ${item.last_name}`,
        value: item.uuid
      }
    })
  }

  private async resolveLead () {
    try {
      const payload: any = {
        event_uuid: this.$route.params.event_uuid,
        participant_uuid: this.$data.unresolvedLeadsUuidToUse,
        unknown_uuid: this.$data.unknownUuid
      }
      await this.$store.dispatch('leads/resolveLead', payload)
      Container.get(Notification).success('Lead was successfully resolved')
      this.handleShowUnresolvedLeadsModalClose()
      this.$data.tables.unresolvedLeads.loading = true
      await this.fetchAttendeeLeads()
    } catch (e) {
      Container.get(ErrorHandlerService).error(e)
    } finally {
      this.$data.tables.unresolvedLeads.loading = false
    }
  }

  private async fetchAttendeeLeadsAppCodes () {
    try {
      this.$data.tables.app_codes.loading = true
      const payload = {
        event_uuid: this.event_uuid,
        participant_uuid: this.attendee_uuid,
        queryParam: ''
      }
      await this.$store.dispatch('registration/getRegistrationLeadsAppCodes', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.tables.app_codes.loading = false
    }
  }

  get attendeeAppCodes () {
    if (Array.isArray(this.$store.state.registration.registrationLeadsAppCodes)) {
      return this.$store.state.registration.registrationLeadsAppCodes
    } else {
      return []
    }
  }

  private async fetchAttendeeQualifiers () {
    try {
      this.$data.tables.qualifiers.loading = true
      const payload = {
        event_uuid: this.event_uuid,
        participant_uuid: this.attendee_uuid,
        queryParam: ''
      }
      await this.$store.dispatch('registration/getRegistrationQualifiers', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.tables.qualifiers.loading = false
    }
  }

  get attendeeQualifiers () {
    if (Array.isArray(this.$store.state.registration.registrationQualifiers)) {
      return this.$store.state.registration.registrationQualifiers
    } else {
      return []
    }
  }

  private async fetchAttendeeLeads () {
    try {
      this.$data.tables.leads.loading = true
      const payload = {
        event_uuid: this.event_uuid,
        participant_uuid: this.attendee_uuid,
        queryParam: ''
      }
      await this.$store.dispatch('registration/getRegistrationLeads', payload)
      this.processAttendeeLeads()
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.tables.leads.loading = false
    }
  }

  private processAttendeeLeads () {
    const leadsItemsResolved: LeadsItem[] = []
    const leadsItemsUnresolved: LeadsItem[] = []

    for (const leadIndex in this.$store.state.registration.registrationLeads) {
      const lead = this.$store.state.registration.registrationLeads[leadIndex]
      let mediaPrint = ''
      for (const mediaIndex in lead.media) {
        const media = lead.media[mediaIndex]
        mediaPrint += media.url + '\n'
      }
      let qualifiersPrint = ''
      const leadQualifiers = lead.lead_qualifiers.filter(leadQualifier => leadQualifier.qualifier)
      for (const qualifierIndex in leadQualifiers) {
        const lead_qualifier = lead.lead_qualifiers[qualifierIndex]
        qualifiersPrint += lead_qualifier.qualifier.description + (lead_qualifier.value ? '-' + lead_qualifier.value : '') + '\n'
      }
      const leadObject = {
        unknown_participant_uuid: lead?.unknown_participant ? lead.unknown_participant.uuid : null,
        participant: lead.participant_short ? lead.participant_short.first_name + ' ' + lead.participant_short.last_name : '',
        scan_time: lead.scan_time,
        barcode: lead.barcode,
        notes: lead.notes,
        used_code: lead.used_code ? lead.used_code.app_code.code : '',
        media: mediaPrint,
        qualifiers: qualifiersPrint
      }
      if (lead.participant_short) {
        leadsItemsResolved.push(leadObject)
      } else {
        leadsItemsUnresolved.push(leadObject)
      }
    }
    this.$data.attendeeLeadsResolved.splice(0, this.$data.attendeeLeadsResolved.length)
    this.$data.attendeeLeadsUnresolved.splice(0, this.$data.attendeeLeadsUnresolved.length)
    this.$data.attendeeLeadsResolved.push(...leadsItemsResolved)
    this.$data.attendeeLeadsUnresolved.push(...leadsItemsUnresolved)
  }

  handleShowAddLeadsAppCodeForm () {
    this.$data.forms.showAddLeadsAppCodeForm = true
  }

  handleShowAddQualifierForm () {
    this.$data.forms.showAddQualifiersForm = true
  }

  async handleCloseAddLeadsAppCodeForm () {
    this.$data.forms.showAddLeadsAppCodeForm = false
    await this.fetchAttendeeLeadsAppCodes()
  }

  async handleCloseAddQualifiersForm () {
    this.$data.forms.showAddQualifiersForm = false
    await this.fetchAttendeeQualifiers()
  }

  async handleCloseEditLeadsAppCodeForm () {
    this.$data.codeDialog = false
    await this.fetchAttendeeLeadsAppCodes()
  }

  showEditCodeDialog (item) {
    this.$data.showedEditCode = item || {}
    this.$data.codeDialog = !this.$data.codeDialog
  }
}
