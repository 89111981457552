import { Component } from 'vue-property-decorator'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import Container from 'typedi'
import Notification from '@/modules/common/services/notification.service'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import { mapState } from 'vuex'

@Component({
  name: 'GtrOptionGroupsTab',
  computed: mapState('formbuilder', ['form'])
})
export default class GtrOptionGroupsTab extends GtrSuper {
  event_uuid = ''
  loading = false

  attendee_uuid = ''

  form!: Record<string, any>
  addDevice = false
  removeDevice = false

  manageDeviceMode: 'add' | 'remove' = 'add'

  deviceHeaders = [
    {
      text: 'Device Type',
      align: 'start',
      sortable: false,
      value: 'uuid'
    }, {
      text: 'Total',
      align: 'end',
      sortable: false,
      value: 'count'
    }
  ]

  device: {
    uuid: null | string;
    qty: number;
  } = {
    uuid: null,
    qty: 0
  }

  data () {
    return {
      lrActivationSelection: null
      // data
    }
  }

  /*
   * LIFECYCLES and METHODS
   */
  created () {
    this.event_uuid = this.$route.params.event_uuid
    this.attendee_uuid = this.$route.params.attendee_uuid
  }

  async mounted () {
    await this.fetchRegForm()
  }

  /*
   * Dispatch Methods
   */

  getOptions (options, removal = false) {
    const modifiedOptions = options.map((item) => {
      // Check if it's the specific item you want to modify
      if (item.name === 'Lead Retrieval Mobile App') {
        if (removal) {
          return
        }
        // Modify the 'name' and 'uuid' fields as needed
        return {
          ...item,
          name: 'Lead Retrieval Activations',
          uuid: 'Lead Retrieval Activations'
        }
      }
      return item
    })
    return modifiedOptions
  }

  async fetchRegForm () {
    try {
      const payload = {
        event_uuid: this.event_uuid
      }
      await this.$store.dispatch('formbuilder/getForm', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  async handleDeviceAmount ({ name, uuid: group_uuid, selectedOptions, mode = 'add' }: any, add = true) {
    try {
      if (this.$data.lrActivationSelection) {
        await this.updateOption(this.lrActivationsGroup.uuid, this.$data.lrActivationSelection, 1, true) // update option.
        Container.get(Notification).success('Updated')
        await this.$store.dispatch('event/getEventAllContent', this.event_uuid)
        return false
      }
      const { uuid, qty } = this.device
      if (!uuid) throw new Error('No device uuid provided.')
      if (!qty) throw new Error('No device quantity provided.')

      const intQty = typeof qty !== 'number' ? parseInt(qty) : qty // parse the qty if it's not a number
      const exists = selectedOptions !== undefined && selectedOptions.hasOwnProperty(uuid)

      let quantity = exists ? selectedOptions[uuid] : 0

      if (!add && intQty > selectedOptions[uuid]) throw new Error('Removing too many devices.')

      quantity = add ? (quantity + intQty) : (quantity - intQty)

      // if (quantity < 0) Container.get(Notification).error('Final quantity less than 0, setting to zero.')

      await this.updateOption(group_uuid, uuid, quantity, false) // update option.
      Container.get(Notification).success(`${qty} ${name} successfully ${add ? 'added' : 'removed'}`)
    } catch (error) {
      Container.get(Notification).error((error as Error).message)
    } finally {
      this.addDevice = false // close modal.
      this.removeDevice = false
      this.resetDeviceForm()
    }
  }

  private resetDeviceForm (): void {
    this.device = {
      uuid: null,
      qty: 0
    } // reset form.
  }

  async updateOption (option_group_uuid, option_uuid, quantity, removeOtherSelections) {
    try {
      this.loading = true
      await this.$store.dispatch('registration/updateOptionQty', {
        event_uuid: this.event_uuid,
        participant_uuid: this.attendee_uuid,
        option_group_uuid,
        option_uuid,
        qty: quantity,
        removeOtherSelections
      })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.loading = false
    }
  }

  /*
   * Save Option Group Changes
   */

  async editRegistrationOptionGroups () {
    try {
      this.$store.dispatch('common/showLoader', { value: true })
      const optionGroups = this.optionGroupsToDisplay
      for (const item in this.optionGroupsToDisplayLR) {
        optionGroups.push(this.optionGroupsToDisplayLR[item]) // add lr items
      }
      for (const optionGroup of optionGroups) {
        let { selectedOptions, selectedOptionsCC } = optionGroup
        if (typeof selectedOptions === 'string') {
          selectedOptions = [selectedOptions]
        }
        if (typeof selectedOptionsCC === 'string') {
          selectedOptionsCC = [selectedOptionsCC]
        }
        if (typeof selectedOptions === 'object') {
          const selectedOptionsTemp: any[] = []
          for (const key in selectedOptions) {
            if (selectedOptions.hasOwnProperty(key)) {
              selectedOptionsTemp.push(selectedOptions[key])
            }
          }
          selectedOptions = selectedOptionsTemp
        }

        if (selectedOptions === selectedOptionsCC) {
          continue
        } else {
          if (selectedOptionsCC) {
            for (const optionCC of selectedOptionsCC) {
              if (!selectedOptions || !selectedOptions.includes(optionCC)) {
                await this.updateOption(optionGroup.uuid, optionCC, -1, false)
              }
            }
          }
          if (selectedOptions) {
            for (const option of selectedOptions) {
              if (!selectedOptionsCC || !selectedOptionsCC.includes(option)) {
                await this.updateOption(optionGroup.uuid, option, 1, false)
              }
            }
          }
        }
      }
      const payload = {
        event_uuid: this.event_uuid,
        registration_uuid: this.attendee_uuid,
        data: {}
      }
      const event_uuid = this.event_uuid
      const participant_uuid = this.attendee_uuid
      await this.$store.dispatch('registration/editRegistration', payload)
      this.$store.dispatch('registration/getRegistration', { event_uuid, participant_uuid })
      this.$store.dispatch('registration/getRegistrationAuditLog', { event_uuid, participant_uuid })
      this.$store.dispatch('registration/getRegistrationFees', { event_uuid, participant_uuid })
      this.$store.dispatch('registration/getLRFees', { event_uuid, participant_uuid })
      Container.get(Notification).success('Option groups successfully updated.')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$store.dispatch('common/hideLoader', { value: true })
    }
  }

  /*
   * COMPUTED
   */
  get optionGroupsToDisplay () {
    return this.getOptionGroupsDisplayByType(['OPTIONGRP', 'SESSIONGRP'])
  }

  get optionGroupsToDisplayLR () {
    return this.getOptionGroupsDisplayByType(['LROPTIONGRP'])
  }

  get optionGroupsToDisplayAll () {
    return this.getOptionGroupsDisplayByType(['OPTIONGRP', 'SESSIONGRP', 'LROPTIONGRP'])
  }

  get isOrdersEnabled (): boolean {
    return this.$store.state.event.eventModules && this.$store.state.event.eventModules.LEADS && this.$store.state.event.eventModules.LEADS.enabled
  }

  get isRegistrationEnabled (): boolean {
    return this.$store.state.event.eventModules && this.$store.state.event.eventModules.REGISTRATION && this.$store.state.event.eventModules.REGISTRATION.enabled
  }

  get deviceBreakdown () {
    const [lrDevices] = this.optionGroupsToDisplayLR.filter((group: Record<string, any>) => group.name === 'Lead Retrieval Devices')
    return lrDevices
  }

  get lrActivationsGroup () {
    return this.optionGroupsToDisplayLR.find((group) => group.name === 'Lead Retrieval Activations')
  }

  get lrActivations () {
    const lrDevices = this.lrActivationsGroup.options.map((group) => ({
      text: group.name,
      value: group.uuid
    }))
    return lrDevices
  }

  get selectedDevices () {
    const selections: any[] = []
    if (this.deviceBreakdown) {
      const { options, selectedOptions } = this.deviceBreakdown

      for (const { uuid, name } of options) {
        if (selectedOptions && (uuid in selectedOptions)) {
          selections.push({
            uuid,
            name,
            count: selectedOptions[uuid]
          })
        }
      }
    }
    const { options, selectedOptions } = this.lrActivationsGroup
    for (const { uuid, name } of options) {
      if (selectedOptions === uuid) {
        selections.push({
          selectedOptions,
          name: 'Lead Retrieval Activations: ' + name,
          count: 1
        })
      }
    }
    return selections
  }

  private getOptionGroupsDisplayByType (types) {
    // we're gonna build the that data we want
    // get info needed from options groups
    const optionGroups = this.$store.state.event.eventAllContent.option_groups
    const optionGroupsToDisplay: any[] = []
    if (optionGroups) {
      optionGroups.forEach(optionGroup => {
        if (types.includes(optionGroup.type)) { // hide track order fields until they can be properly displayed and updated
          optionGroupsToDisplay.push({
            name: optionGroup.name,
            options: optionGroup.options,
            uuid: optionGroup.uuid,
            multiple: false
          })
        }
      })
    }
    // get field types for option groups on form
    const optionGroupFieldTypes: any[] = []
    const pageData = this.form.page_data
    if (pageData) {
      pageData.forEach(page => {
        const pageFields = page.fields
        pageFields.forEach(field => {
          if (field.option_group_uuid) {
            optionGroupFieldTypes.push({
              uuid: field.option_group_uuid,
              type: field.type
            })
          }
        })
      })
    }
    // find which option groups are set to checkbox
    for (let i = 0; i < optionGroupFieldTypes.length; i++) {
      for (let j = 0; j < optionGroupsToDisplay.length; j++) {
        if (optionGroupsToDisplay[j].uuid === optionGroupFieldTypes[i].uuid) {
          Object.assign(optionGroupsToDisplay[j], {
            type: optionGroupFieldTypes[i].type
          })
          if (optionGroupFieldTypes[i].type === 'checkbox' || optionGroupFieldTypes[i].type === 'sessions') {
            optionGroupsToDisplay[j].multiple = true
          }
        }
      }
    }
    // get option group selections
    const selectedOptionQty = this.$store.state.registration.registration.selected_option_qty
    for (const groupId in selectedOptionQty) {
      const groupOptions = selectedOptionQty[groupId]
      const selections: any[] = []
      const selectionsQty = Object.keys(groupOptions).sort().reduce(function (acc, key) {
        acc[key] = groupOptions[key]
        return acc
      }, {})

      for (const optionId in groupOptions) {
        const optionQty = groupOptions[optionId]
        if (optionQty > 0) {
          selections.push(optionId)
        }
        for (let i = 0; i < optionGroupsToDisplay.length; i++) {
          if (optionGroupsToDisplay[i].uuid === groupId) {
            if (optionGroupsToDisplay[i].name === 'Lead Retrieval Devices') {
              Object.assign(optionGroupsToDisplay[i], {
                // checkboxes will always allow multiple selections, so always set to an array
                selectedOptions: selectionsQty,
                selectedOptionsCC: selections // for comparison in this.removeOption()
              })
            } else if (optionGroupsToDisplay[i].type === 'checkbox' || optionGroupsToDisplay[i].type === 'sessions') {
              Object.assign(optionGroupsToDisplay[i], {
                // checkboxes will always allow multiple selections, so always set to an array
                selectedOptions: selections,
                selectedOptionsCC: selections // for comparison in this.removeOption()
              })
            } else {
              Object.assign(optionGroupsToDisplay[i], {
                // for other dropdowns
                // single value cannot be in an array
                selectedOptions: selections[0],
                selectedOptionsCC: selections[0]
              })
            }
          }
        }
      }
    }
    return optionGroupsToDisplay
  }

  onClose (): void {
    this.addDevice = false
    this.removeDevice = false
  }

  addDeviceModal (): void {
    this.addDevice = true
  }

  removeDeviceModal (): void {
    this.removeDevice = true
  }
}
