import { Component } from 'vue-property-decorator'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import Container from 'typedi'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'

@Component({
  name: 'GtrBrowsingHistoryTab'
})
export default class GtrBrowsingHistoryTab extends GtrSuper {
  event_uuid = ''

  attendee_uuid = ''

  data () {
    return {
      browsing_history: {
        loading: false,
        headers: [{
          text: 'Date',
          align: 'start',
          sortable: false,
          value: 'created_at'
        },
        {
          text: 'Browser',
          align: 'start',
          sortable: false,
          value: 'browser_version'
        },
        {
          text: 'OS',
          align: 'start',
          sortable: false,
          value: 'os'
        },
        {
          text: 'IP',
          align: 'start',
          sortable: false,
          value: 'ip'
        },
        {
          text: 'Location',
          align: 'start',
          sortable: false,
          value: 'location'
        },
        {
          text: 'Url',
          align: 'start',
          sortable: false,
          value: 'url'
        }]
      }
    }
  }

  created () {
    this.event_uuid = this.$route.params.event_uuid
    this.attendee_uuid = this.$route.params.attendee_uuid
  }

  mounted () {
    try {
      this.fetchRegistrationAnalytics()
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
    }
  }

  private async fetchRegistrationAnalytics () {
    try {
      this.$data.browsing_history.loading = true
      const payload = {
        event_uuid: this.event_uuid,
        participant_uuid: this.attendee_uuid
      }
      await this.$store.dispatch('registration/getRegistrationAnalytics', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.browsing_history.loading = false
    }
  }

  get allAnalytics () {
    const allAnalytics: any = []

    try {
      const registrationAnalytics = this.$store.state.registration.registrationAnalytics
      for (const itemIndex in registrationAnalytics.data) {
        const item = registrationAnalytics.data[itemIndex]
        for (const analyticDataIndex in item.analytics) {
          const analyticData = item.analytics[analyticDataIndex]
          analyticData.unique_id = item.unique_id
          allAnalytics.push(analyticData)
        }
      }
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
    }

    return allAnalytics
  }
}
