import { render, staticRenderFns } from "./activities-tab.view.vue?vue&type=template&id=262134a6&scoped=true&"
import script from "./activities-tab.view.ts?vue&type=script&lang=ts&"
export * from "./activities-tab.view.ts?vue&type=script&lang=ts&"
import style0 from "./activities-tab.view.vue?vue&type=style&index=0&id=262134a6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "262134a6",
  null
  
)

export default component.exports