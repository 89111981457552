import { Component, Watch } from 'vue-property-decorator'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import Container from 'typedi'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import { mapState } from 'vuex'

interface LineItem {
  description: string;
  qty: string | number;
  total: string | number;
  type: string;
  created_at: string;
}

@Component({
  name: 'GtrLineItemsTab',
  computed: {
    ...mapState('registration', ['LRFees', 'registrationFees'])
  }
})
export default class GtrLineItemsTab extends GtrSuper {
  data () {
    return {
      lineItemsData: [],
      lineItemsLRData: [],
      reg_line_items_loading: true,
      lr_line_items_loading: true,
      line_items: {
        loading: true,
        headers: [
          {
            text: 'Description',
            align: 'start',
            sortable: false,
            value: 'description'
          },
          {
            text: 'Quantity',
            align: 'start',
            sortable: false,
            value: 'qty'
          },
          {
            text: 'Amount',
            align: 'start',
            sortable: false,
            value: 'total'
          },
          {
            text: 'Type',
            align: 'start',
            sortable: false,
            value: 'type'
          },
          {
            text: 'Date',
            align: 'start',
            sortable: false,
            value: 'created_at'
          }
        ]
      }
    }
  }

  @Watch('LRFees', { immediate: true })
  onLRFeesChange (payload: any) {
    if (payload && payload.ledgers) {
      const lineItems: LineItem[] = []
      try {
        payload.ledgers.forEach(ledger => {
          lineItems.push({
            description: ledger.description,
            qty: ledger.qty,
            total: ledger.total,
            type: ledger.type,
            created_at: ledger.created_at
          })
        })
        this.$data.lr_line_items_loading = false
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
      }
      this.$data.lineItemsLRData = lineItems
    }
  }

  @Watch('registrationFees', { immediate: true })
  onRegistrationFeesChange (payload: any) {
    if (payload && payload.ledgers) {
      const lineItems: LineItem[] = []
      try {
        payload.ledgers.forEach(ledger => {
          lineItems.push({
            description: ledger.description,
            qty: ledger.qty,
            total: ledger.total,
            type: ledger.type,
            created_at: ledger.created_at
          })
        })
        this.$data.reg_line_items_loading = false
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
      }
      this.$data.lineItemsData = lineItems
    }
  }

  get lineItems () {
    return this.$data.lineItemsData
  }

  get isOrdersEnabled (): boolean {
    return this.$store.state.event.eventModules && this.$store.state.event.eventModules.LEADS && this.$store.state.event.eventModules.LEADS.enabled
  }

  get lineItemsLR () {
    return this.$data.lineItemsLRData
  }
}
