import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import { ActionType } from '@/modules/common/enums/action-types.enum'
import { ValidationObserver } from 'vee-validate'
import { Component, Ref, Prop, Watch } from 'vue-property-decorator'
import Container from 'typedi'
import Notification from '@/modules/common/services/notification.service'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import { mapState } from 'vuex'

@Component({
  name: 'GtrNewAttendeeFormGroup',
  computed: {
    ...mapState('attendee', ['attendees'])
  }
})
export default class GtrNewAttendeeForm extends GtrSuper {
    @Prop({ required: true, type: Boolean, default: false })
    visible: boolean | undefined

    @Prop({ required: true, type: Array, default: '' })
    custom_fields: Array<object> | undefined

    @Prop({ required: false, type: String, default: null })
    group_uuid: string | undefined

    @Ref()
    readonly observerNewAttendeeForm!: InstanceType<typeof ValidationObserver>;

    data () {
      return {
        existingAttendee: [],
        attendeesFiltered: [],
        submitting: false,
        showForm: false,
        newAttendee: {
          first_name: null,
          last_name: null,
          email: null
        }
      }
    }

    @Watch('visible', { immediate: true })
    onVisibleValueChange (newVisibleValue: boolean) {
      this.$data.showForm = newVisibleValue
    }

    @Watch('attendees', { immediate: true })
    onAttendeesValueChange (newAttendees: any) {
      const newAttendeesData = newAttendees
      this.$data.attendeesFiltered = newAttendeesData.filter(attendee => this.group_uuid !== attendee.group_uuid)
        .map(attendee => ({
          text: attendee.first_name + ' ' + attendee.last_name,
          value: attendee.uuid
        }))
    }

    async mounted () {
      this.clearFormAndModel()
      this.fetchAttendees()
    }

    async addExistingAttendee () {
      this.$data.submitting = true
      if (this.$data.existingAttendee.length) {
        for (const attendee of this.$data.existingAttendee) {
          await this.addParticipantToGroup(attendee.value)
        }
      }
      this.$emit('action', { type: ActionType.SUCCESS, message: 'Attendees successfully added to group.' })
      Container.get(Notification).success('Attendees successfully added to group.')
      this.$emit('close')
      this.clearFormAndModel()
      this.fetchAttendees()
    }

    private async fetchAttendees (): Promise<void> {
      try {
        const listViewFields = [{ field: 'first_name' }, { field: 'last_name' }, { field: 'email' }, { field: 'status' }, { field: 'registration_type' }, { field: 'group_uuid' }]
        // Fetch the total number of records with a limit of 1
        const initialResponse = await this.$store.dispatch('attendee/fetchAttendeesListViewDirect', {
          event_uuid: this.$route.params.event_uuid,
          listViewFields,
          skip: 0,
          limit: 1,
          meta: 1
        })
        const totalResults = initialResponse.data.total
        const limit = 1000
        const totalPages = Math.ceil(totalResults / limit)
        // Create an array of Promises for fetching attendees in parallel
        const promises: Promise<any>[] = []
        const responses: any[] = []
        const participantsInOrder = {}
        for (let i = 0; i < totalPages; i++) {
          const promise = this.$store.dispatch('attendee/fetchAttendeesListViewDirect', {
            event_uuid: this.$route.params.event_uuid,
            listViewFields,
            skip: i * limit,
            limit
          })
          promises.push(promise)
          promise.then(response => {
            participantsInOrder[i] = response.data
          })
          responses.push(promise)
        }
        // Wait for all requests to settle
        await Promise.all(promises)
        const attendees: any = []
        for (let i = 0; i < totalPages; i++) {
          for (let p = 0; p < participantsInOrder[i].length; p++) {
            attendees.push(participantsInOrder[i][p])
          }
        }
        this.$store.commit('attendee/SET_ATTENDEES', attendees)
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.loading = false
      }
    }

    async addParticipantToGroup (participant_uuid) {
      try {
        const payload = {
          event_uuid: this.$route.params.event_uuid,
          group_uuid: this.group_uuid,
          participant_uuid: participant_uuid,
          data: {}
        }
        await this.$store.dispatch('registration/addParticipantToGroup', payload)
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.newGroupName = null
      }
    }

    async submit () {
      try {
        this.$data.submitting = true
        const attendee = await this.$store.dispatch('attendee/addAttendee', { event_uuid: this.$route.params.event_uuid, data: this.$data.newAttendee })
        if (this.group_uuid) {
          await this.addParticipantToGroup(attendee.uuid)
        }
        this.$emit('action', { type: ActionType.SUCCESS, message: 'Attendee successfully created.' })
        Container.get(Notification).success('Attendees successfully created.')
        this.$emit('close')
        this.clearFormAndModel()
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.submitting = false
      }
    }

    handleClose () {
      this.clearFormAndModel()
      this.$emit('close')
    }

    private showServerErrors (errors: Record<string, string>): void {
      this.observerNewAttendeeForm.setErrors(errors)
    }

    private clearFormAndModel () {
      this.$data.newAttendee = {
        first_name: null,
        last_name: null,
        email: null
      }
      this.$data.existingAttendee = []
      this.observerNewAttendeeForm.reset()
    }
}
