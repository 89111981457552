import { Component, Watch } from 'vue-property-decorator'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import { mapState, mapGetters } from 'vuex'
import Container from 'typedi'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'

@Component({
  name: 'GtrActivityTab',
  computed: {
    ...mapState('registration', ['registrationEmailsSent']),
    ...mapState('sessions', ['activitiesByParticipant']),
    ...mapGetters('surveys', ['completedParticipantSurveys'])
  }
})
export default class GtrActivityTab extends GtrSuper {
  event_uuid = ''

  attendee_uuid = ''

  data () {
    return {
      changedCapList: {},
      loading: false,
      display_emails: false,
      display_transactions: false,
      display_scans: false,
      display_sessions: false,
      display_surveys: false,
      page: 1,
      itemsPerPage: 10,
      itemsPerPageArray: [10, 20, 'All'],
      session_data: [],
      showedEmail: {
        to: '',
        cc: '',
        bcc: '',
        html: '',
        send_grid_activity: ''
      },
      dialog: false
    }
  }

  created () {
    this.event_uuid = this.$route.params.event_uuid
    this.attendee_uuid = this.$route.params.attendee_uuid
  }

  async mounted () {
    try {
      await this.fetchActivities()
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  @Watch('activitiesByParticipant', { immediate: true })
  onActivityChange (payload: any) {
    this.$data.activities_by_participant = payload
    for (const index in this.$data.activities_by_participant) {
      const act = this.$data.activities_by_participant[index]
      this.$data.activities_by_participant[index].current_value = act.activity_cap
    }
  }

  private async fetchActivities () {
    try {
      const payload = {
        event_uuid: this.event_uuid,
        participant_uuid: this.attendee_uuid
      }
      await this.$store.dispatch('sessions/fetchActivitiesForParticipant', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  async saveActivities () {
    for (const activityIndex in this.$data.activities_by_participant) {
      const activity = this.$data.activities_by_participant[activityIndex]
      if (activity.activity_cap !== activity.current_value) {
        const payload = {
          event_uuid: this.event_uuid,
          participant_uuid: this.attendee_uuid,
          session_uuid: activity.session_uuid,
          activity_uuid: activity.activity_uuid,
          cap: activity.current_value
        }
        await this.$store.dispatch('sessions/overrideCap', payload)
      }
    }
    Container.get(Notification).success('Succesfully Updated')
    await this.fetchActivities()
  }
}
