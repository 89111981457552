import { Component, Watch } from 'vue-property-decorator'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import { mapGetters, mapState } from 'vuex'
import Container from 'typedi'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import { SurveySummary } from '@/interfaces'

@Component({
  name: 'GtrSurveysTab',
  computed: {
    ...mapState('registration', ['registrationEmailsSent']),
    ...mapState('sessions', ['sessionsByParticipant']),
    ...mapGetters('surveys', ['completedParticipantSurveys', 'incompleteParticipantSurveys', 'activeCertificate'])
  }
})
export default class GtrSurveysTab extends GtrSuper {
  event_uuid = ''

  attendee_uuid = ''

  completedParticipantSurveys!: SurveySummary[];
  incompleteParticipantSurveys!: SurveySummary[];
  activeCertificate!: Record<string, any>
  transcriptUrl = ''
  certificateUrl = ''

  data () {
    return {
      loading: false
    }
  }

  created () {
    this.event_uuid = this.$route.params.event_uuid
    this.attendee_uuid = this.$route.params.attendee_uuid
  }

  async mounted () {
    try {
      this.$data.loading = true
      await this.fetchParticipantSurveys()
      await this.fetchParticipantCertificates()
      await this.fetchSurveys()
      await this.fetchCertificates()
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async fetchSurveys (): Promise<void> {
    try {
      await this.$store.dispatch('surveys/fetchSurveys', {
        event_uuid: this.event_uuid,
        participant_uuid: this.attendee_uuid
      })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async fetchCertificates (): Promise<void> {
    try {
      const response = await this.$store.dispatch('surveys/getCertificates', {
        event_uuid: this.event_uuid,
        participant_uuid: this.attendee_uuid
      })
      if (response && this.activeCertificate) {
        await this.fetchCertificatesAndTranscripts(this.activeCertificate.uuid)
      }
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async fetchCertificatesAndTranscripts (certificate_uuid: string) {
    try {
      const response = await this.$store.dispatch('surveys/getCertificatesExport', {
        event_uuid: this.event_uuid,
        participant_uuid: this.attendee_uuid,
        certificate_uuid
      })
      this.certificateUrl = response.certificate
      this.transcriptUrl = response.transcript
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async fetchParticipantSurveys () {
    try {
      const payload = {
        event_uuid: this.event_uuid,
        participant_uuid: this.attendee_uuid,
        addedQueryParam: ''
      }
      await this.$store.dispatch('registration/getRegistrationEmailsSent', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async fetchParticipantCertificates () {
    try {
      const payload = {
        event_uuid: this.event_uuid,
        participant_uuid: this.attendee_uuid
      }
      await this.$store.dispatch('sessions/getSessionsByParticipant', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async handleResetSurveyMaxAttempts (sessionUuid: string | undefined) {
    try {
      // if sessionUuid === undefined is an extra measure. The button should not appear if the survey type is not 'session'.
      if (sessionUuid === undefined) {
        Container.get(Notification).error('Session is undefined.')
      } else {
        const payload = {
          event_uuid: this.event_uuid,
          participant_uuid: this.attendee_uuid,
          session_uuid: sessionUuid
        }
        await this.$store.dispatch('surveys/resetSurveyMaxAttempts', payload)
        Container.get(Notification).success('Max attempts reset.')
      }
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }
}
