import { Component, Watch } from 'vue-property-decorator'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import Container from 'typedi'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import { mapState } from 'vuex'

interface FeesItem {
  balance: string;
  payment: string;
  total: string;
  subtotal: string;
  discount: string;
  tax_total: string;
}
@Component({
  name: 'GtrFeesTab',
  computed: {
    ...mapState('event', ['eventAllContent'])
  }
})
export default class GtrFeesTab extends GtrSuper {
  data () {
    return {
      feesItemsLoading: true,
      groupFeesItemsLoading: true,
      feesLRItemsLoading: true,
      event_all_content: null,
      payment_processor_currency: null,
      fees: {
        loading: true,
        headers: [
          {
            text: 'Balance',
            align: 'start',
            sortable: false,
            value: 'balance'
          },
          {
            text: 'Payment',
            align: 'start',
            sortable: false,
            value: 'payment'
          },
          {
            text: 'Total',
            align: 'start',
            sortable: false,
            value: 'total'
          },
          {
            text: 'Subtotal',
            align: 'start',
            sortable: false,
            value: 'subtotal'
          },
          {
            text: 'Discount',
            align: 'start',
            sortable: false,
            value: 'discount'
          },
          {
            text: 'Tax total',
            align: 'start',
            sortable: false,
            value: 'tax_total'
          }
        ]
      }
    }
  }

  get isOrdersEnabled (): boolean {
    return this.$store.state.event.eventModules && this.$store.state.event.eventModules.LEADS && this.$store.state.event.eventModules.LEADS.enabled
  }

  @Watch('eventAllContent', { immediate: true })
  onEventAllContentChange (value: any) {
    if (value) {
      this.$data.event_all_content = value
      this.$data.payment_processor_currency = this.$data.event_all_content.payment_processor_currency
    }
  }

  toCurrency (value, currency = 'usd') {
    if (typeof value !== 'number') {
      return value
    }
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    })
    return formatter.format(value) + (currency === 'cad' ? 'CAD' : '')
  }

  get feesItems () {
    this.$data.feesItemsLoading = true
    const feesItems: FeesItem[] = []

    try {
      let fee: any
      if (this.$store.state.registration.registrationFees) {
        fee = this.$store.state.registration.registrationFees
      }
      if (this.$store.state.registration.registrationFees.balance !== undefined) {
        this.$data.feesItemsLoading = false
      }

      if (fee && fee.currency !== undefined && fee.balance !== undefined && fee.transaction_total !== undefined && fee.total !== undefined && fee.subtotal !== undefined && fee.promoCode !== undefined && fee.discount_total !== undefined && fee.subtotal_tax !== undefined) {
        feesItems.push({
          balance: this.toCurrency(fee.balance, this.$data.payment_processor_currency),
          payment: this.toCurrency(fee.transaction_total, this.$data.payment_processor_currency),
          total: this.toCurrency(fee.total, this.$data.payment_processor_currency),
          subtotal: this.toCurrency(fee.subtotal, this.$data.payment_processor_currency),
          discount: fee.promoCode ? `(${fee.promoCode})` : this.toCurrency(fee.discount_total, this.$data.payment_processor_currency),
          tax_total: this.toCurrency(fee.subtotal_tax, this.$data.payment_processor_currency)
        })
      }
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
    }
    return feesItems
  }

  get groupFeesItems () {
    this.$data.groupFeesItemsLoading = true
    const feesItems: FeesItem[] = []

    try {
      let fee: any
      if (this.$store.state.registration.registration.participant_group_uuid && this.$store.state.registration.groupFees) {
        fee = this.$store.state.registration.groupFees
      }
      if (this.$store.state.registration.groupFees.balance !== undefined) {
        this.$data.groupFeesItemsLoading = false
      }

      if (fee && fee.currency !== undefined && fee.balance !== undefined && fee.transaction_total !== undefined && fee.total !== undefined && fee.subtotal !== undefined && fee.promoCode !== undefined && fee.discount_total !== undefined && fee.subtotal_tax !== undefined) {
        feesItems.push({
          balance: this.toCurrency(fee.balance, this.$data.payment_processor_currency),
          payment: this.toCurrency(fee.transaction_total, this.$data.payment_processor_currency),
          total: this.toCurrency(fee.total, this.$data.payment_processor_currency),
          subtotal: this.toCurrency(fee.subtotal, this.$data.payment_processor_currency),
          discount: fee.promoCode ? `(${fee.promoCode})` : this.toCurrency(fee.discount_total, this.$data.payment_processor_currency),
          tax_total: this.toCurrency(fee.subtotal_tax, this.$data.payment_processor_currency)
        })
      }
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
    }

    return feesItems
  }

  get feesLRItems () {
    this.$data.feesLRItemsLoading = true
    const feesItems: FeesItem[] = []

    try {
      let fee: any
      if (this.$store.state.registration.LRFees) {
        fee = this.$store.state.registration.LRFees
      }
      if (this.$store.state.registration.LRFees.balance !== undefined) {
        this.$data.feesLRItemsLoading = false
      }

      if (fee && fee.currency !== undefined && fee.balance !== undefined && fee.transaction_total !== undefined && fee.total !== undefined && fee.subtotal !== undefined && fee.promoCode !== undefined && fee.discount_total !== undefined && fee.subtotal_tax !== undefined) {
        feesItems.push({
          balance: this.toCurrency(fee.balance, this.$data.payment_processor_currency),
          payment: this.toCurrency(fee.transaction_total, this.$data.payment_processor_currency),
          total: this.toCurrency(fee.total, this.$data.payment_processor_currency),
          subtotal: this.toCurrency(fee.subtotal, this.$data.payment_processor_currency),
          discount: fee.promoCode ? `(${fee.promoCode})` : this.toCurrency(fee.discount_total, this.$data.payment_processor_currency),
          tax_total: this.toCurrency(fee.subtotal_tax, this.$data.payment_processor_currency)
        })
      }
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
    }

    return feesItems
  }
}
